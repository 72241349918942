
body{
  background-color: rgb(250, 247, 247);
  margin: 0;
  color: white;
}
:root{
  --primary: #4b8e5b;
  --secondary:red;
  --product:aliceblue
}

.header{
  width: 100vw;
  height: 5vh;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav{
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  height:10vh;
  background-color: var(--product);
  display: flex;
}

.tagline{
  width: 100vw;
  height: 15vh;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.products{
  height: 85vh;
  overflow: auto;
  background-color:  var(--primary);

}

footer{
  width: 100vw;
  height: 5vh;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card{
  width: 300px;
  height: 350px;
  background-color: var(--primary);
  margin: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

}

.logo{
  width: 25%;
  height: inherit;
  object-fit: contain;
  display: flex;
  align-items: center;
}

.logo-image{
  width: 300px;
  object-fit: contain;
}

.search{
  width: 60%;
  display: flex;
  align-items: center;
}

.cart{
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

}


.cart-icon{
  font-size: 42px;
  color: var(--primary);
  cursor: pointer;
  margin-left: 15px;
}


 .product-image{
  width: 250px;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 20px;
  object-fit: fill;
}

.product-image:hover{
  transform: scale(1.1);
}

.product-deatils{
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
 
}

.incrementer{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.p-image{
  width: 100%;
  height: 200px;
  z-index: 1;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  object-fit: fill;
}

.footer{
  width: 800px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.detail-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
  height: inherit;
}

.logout{
  font-size: 35px;
  margin-top: 7px;
}

.notFound{
  color: var(--primary);
}


.button{
  background-color: var(--primary);
  color: white;
}


.button:hover{
 border: 1px solid var(--primary) !important;
 color: #4b8e5b !important;
}

.productTable{
  margin-top: 30px;
}
/* Ensure the table body has a fixed height that accommodates 5 rows */
.productTable .ant-table-body {
  min-height: 250px; /* Adjust the height based on the row height */
  max-height: 250px; /* Make sure it doesn't grow beyond this */
  overflow: hidden; /* Prevents extra rows when data is less */
  margin-top: 30px;
}

/* You may also want to center empty content when fewer than 5 rows are present */
.productTable .ant-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.pincode{
  margin-top: 10px;
  margin-bottom: 10px;
}

